window.targetParams = [];

const useCheckUser = () => {
    const userType = USER_TYPE.GUEST;
    let userTypeStorage = sessionStorage.getItem('userType');

    /* validate subsequent userType checking from localStorage */
    if (userTypeStorage) {
        return userTypeStorage;
    } else {
        /* validate initial authenticated user or guest user checking */
        const refreshToken = cookieValue('refreshtoken');
        /* validate normal authenticated user checking */
        if (refreshToken) {
            return deriveAuthenticatedUser();
        } else if (window.location != window.parent.location) {
            /* validate punchout authenticated user checking */
            const punchoutRefreshToken = cookieValue('refresh_token');
            /* check if refresh token is present for punchout user */
            if (punchoutRefreshToken) {
                return deriveAuthenticatedUser();
            } else {
                return userType;
            }
        } else {
            /* validate guest user checking */
            return userType;
        }
    }
};

function userCookieRead() {
    let userType = useCheckUser();
    const authorityType = cookieValue('authority_type');
    if (authorityType && authorityType == USER_TYPE.PUNCHOUT) {
        window.targetParams.push('USERTYPE_COOKIE=' + USER_TYPE.PUNCHOUT);
        window.targetGlobalSettings = {
            secureOnly: true
        };
    } else {
        //Redirect to error page if un-authorized user try to access p2p page 
        const punchoutPath = '/procure-to-pay/punchout';
        const domainName = 'sunbeltrentals.com';
        if (window.location.hostname.includes(domainName) && window.location.href.includes(punchoutPath)) {
            window.location.pathname = '/procure-to-pay/error';
        }
        window.targetParams.push('USERTYPE_COOKIE=' + userType);
    }
}

const deriveAuthenticatedUser = () => {
    const projectCookies = getProjectsFromCookie();
    /* if account present, then credit user else cash user */
    if (projectCookies?.CurrentWynneAccount) {
        sessionStorage.setItem('userType', USER_TYPE.CREDIT);
        return USER_TYPE.CREDIT;
    } else {
        sessionStorage.setItem('userType', USER_TYPE.CASH);
        return USER_TYPE.CASH;
    }
};

const USER_TYPE = {
    GUEST: 'guest',
    CASH: 'cash',
    CREDIT: 'credit',
    PUNCHOUT: 'p2p'
};

const getProjectsFromCookie = () => {
    const settingsCookie = cookieValue('Settings');
    const projectObj = {};
    if (settingsCookie) {
        const projectsInfo = settingsCookie.split('&');
        for (let i = 0; i < projectsInfo.length; i++) {
            const [key, value] = projectsInfo[i].split('=');
            projectObj[key] = decodeURIComponent(value);
        }
    }
    return projectObj;
};

const cookieValue = cookieName => {
    let name = cookieName + '=';
    let cookieVal = document.cookie;
    let ca = cookieVal.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
function locationDetailsPage() {
    if (window.location.href.indexOf('location') > -1) {
        const metaTag = document.querySelector('meta[name="Location-Details"]');
        if (metaTag !== null) {
            const branchID = metaTag.getAttribute('data-branchid');
            const branchCity = metaTag.getAttribute('data-branchcity');
            const branchState = metaTag.getAttribute('data-branchstate');
            const branchZip = metaTag.getAttribute('data-branchzip');
            const branchRegion = metaTag.getAttribute('data-branchRegion');
            const specialtyType = metaTag.getAttribute('data-specialtytype');
            const companyId = metaTag.getAttribute('data-companyId');
            window.targetParams.push(
                'branchID=' + branchID,
                'branchCity=' + branchCity,
                'branchState=' + branchState,
                'branchZip=' + branchZip,
                'specialtyType=' + specialtyType,
                'branchAnalysisRegion=' + branchRegion,
                'companyId=' + companyId
            );
        }
    }
}

function isObjectEmpty(val) {
    return val === undefined || val == null || val.length <= 0 ? true : false;
}

function tokenKey(obj) {
    return Object.keys(obj)
        .map(function (k) {
            return k + '' + obj[k];
        })
        .join('');
}

function distinctTokens(arr) {
    var result = arr.reduce(function (acc, e) {
        acc[tokenKey(e)] = e;
        return acc;
    }, {});

    return Object.keys(result).map(function (k) {
        return result[k];
    });
}

sendActivityEvent = function (e) {
    var tokens = e.detail.responseTokens;

    if (isObjectEmpty(tokens)) {
        return;
    }

    window.dataLayer = window.dataLayer || [];
    var uniqueTokens = distinctTokens(tokens);

    uniqueTokens.forEach(function (token) {
        var targetEvent = {
            event: 'target_request_succeeded',
            activity_name: token['activity.name'],
            activity_id: token['activity.id'],
            experience_name: token['experience.name'],
            experience_id: token['experience.id'],
            mbox_name: e.detail.mbox || 'target-global-mbox'
        };

        if (token['offer.id']) {
            targetEvent['offer_id'] = token['offer.id'];
        }

        if (token['offer.name']) {
            targetEvent['offer_name'] = token['offer.name'];
        }
        window.dataLayer.push(targetEvent);
    });
};

function sendActivityDataToGTM() {
    document.addEventListener('at-request-succeeded', sendActivityEvent);
}

userCookieRead();
locationDetailsPage();
sendActivityDataToGTM();

targetPageParams = function () {
    return window.targetParams;
};
